import { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useNavigate, useParams } from 'react-router-dom'
import request from "../../../../utils/axios";
import moment from "moment";
import styles from "./HolidayDetails.module.scss";

import { Row, Col, Button, Divider, Modal, Input, DatePicker } from "antd";

import edit from "../../../../assets/edit.svg";
import deleteIcon from "../../../../assets/DeleteIconWhite.svg";
import dayjs from "dayjs";
import deleteImg from "../../../../assets/delete.png";
import backArrow from "../../../../assets/Recruitment/back.svg";
import { toast } from "react-toastify";
import useScreenSize from "../../../../utils/getScreenSize";

function HolidayDetails() {
  const params = useParams();
  const screenSize = useScreenSize()
  const [holidayData, setHolidayData] = useState([]);
  const [policyName, setPolicyName] = useState("")
  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [holidayDetailsUpdateModal, setHolidayDetailsUpdateModal] = useState(false);
  const [addHolidayDetailsModal, setAddHolidayDetailsModal] = useState(false)
  const [editHolidayDetails, setEditHolidayDetails] = useState({
    id: "",
    name: "",
    holiday_date: "",
    holiday_type: "",
  })
  const [addHolidayDetails, setAddHolidayDetails] = useState({
    holiday_name: "",
    holidays_date: "",
    holiday_type: "",
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteItemDetail, setDeleteItemDetail] = useState({
    month: "",
    holiday_id: ""
  })
  const navigate = useNavigate();
  const fetchHolidayData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/admin/holiday-list/details?id=${params.id}`,
      });
      if (data && data?.data) {
        setHolidayData(data?.data);
        setPolicyName(data?.policy_name)
      }
    } catch (error) { }
  };
  const onFinish = async (values) => {
    try {
      await request({
        method: "post",
        url: `leave/admin/holiday/create`,
        data: {
          ...values,
          id: params.id
        }
      })
      fetchHolidayData()
      setAddHolidayDetailsModal(false)
      addForm.resetFields()
      toast.success("Holiday added successfully", {
        position: "bottom-left",
      })
    }
    catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-left",
      })
      console.log(error)
    }
    // Handle form submission
    // You can send the form values to your server or perform any other necessary actions
  };
  useEffect(() => {
    fetchHolidayData();
  }, []);


  const handleDelete = async (month, holidayId) => {
    try {
      await request({
        method: "delete",
        url: `leave/admin/holiday/delete?id=${deleteItemDetail.holiday_id}`,
      });
      setDeleteModal(false)
      fetchHolidayData()
      toast.success("Holiday deleted successfully", {
        position: "bottom-left",
      })
      // Update UI after deletion
      // setHolidayData((prev) => {
      //   const updatedData = { ...prev };
      //   updatedData[month] = updatedData[month].filter((item) => item.id !== holidayId);
      //   return updatedData;
      // });
    } catch (error) {
      toast.error("Error deleting holiday", {
        position: "bottom-left",
      })
      console.error("Error deleting holiday:", error);
    }
  };
  const handleEdit = (month, holiday) => {
    // Set the holiday details in the state
    setEditHolidayDetails({
      id: holiday?.id,
      name: holiday?.name,
      holiday_date: holiday?.date, // Ensure locale is set
      holiday_type: holiday?.holiday_type
    })

    // Update the form with the selected holiday details
    // editForm.setFieldsValue({
    //   name: holiday.name,
    //   date: moment(holiday.date), // Use moment for DatePicker
    // });

    // Show the modal
    setHolidayDetailsUpdateModal(true);
  };
  const onHandleAddHoliday = (key, value) => {
    setAddHolidayDetails({
      ...addHolidayDetails,
      [key]: value
    });
  }

  const onFinishForEdit = async () => {
    try {
      await request({
        method: "post",
        url: `leave/admin/holiday-list/update`,
        data: {
          ...editHolidayDetails,
          date: editHolidayDetails?.holiday_date,
        }
      });
      fetchHolidayData()
      setHolidayDetailsUpdateModal(false)
      editForm.resetFields()
      toast.success("Holiday updated successfully", {
        position: "bottom-left",
      })
    }
    catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-left",
      })
      console.log(error)
    }
  };

  const mwebrenderHolidayData =()=>{
    return(
           <div style={{
            backgroundColor: "#fff",
            borderRadius: "4px",
          }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            padding: "0px 16px"
          }}>
            <p style={{ fontSize: "20px", fontWeight: "600", margin: "0px" }}>{policyName}</p>
            <Button
              style={{
                backgroundColor: "#008080",
                color: "#fff",
                padding: "0px 16px",
                borderRadius: "4px",
                height: "40px"
              }}
              onClick={() => setAddHolidayDetailsModal(true)}
            >
              Add Holiday
            </Button>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              // flexWrap: "wrap",
              gap: "10px",
              flexDirection: "column"
              // justifyContent: "center",
              // alignItems: "center",

            }}
          >

            {Object.keys(holidayData).map((month, index) => (
              <div
                key={month}
                style={{
                  width: "100%",
                  minWidth: "300px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4
                key={index}
                  style={{
                    color: "#5B5B5B",
                    fontSize: "18px",
                    fontWeight: "500",
                    marginTop: "10px",
                    marginBottom: "10px",
                    padding: "0px 16px",
                    color: "#008080",
                  }}
                >
                  {month}
                </h4>
                <div >
                  {holidayData[month].map((item) => (
                    <div style={{ flexGrow: 1 }}>
                    <Row
                      key={item.id}
                      align="middle"
                      // justify="space-between"
                      style={{
                        padding: "0px 16px",
                      }}
                    >
                      <Col
                        xs={{ span: 3 }}
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            color: "#292929",
                            fontSize: "18px",
                            fontWeight: "500",
                            margin: 0,
                            width: "30px"
                            
                          }}
                        >
                          {moment(item.date).format("DD")}
                        </p>
                      </Col>
                      <Col xs={{ span: 21 }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{
                            padding: "2px"
                          }}>
                            <p
                              style={{
                                color: "#292929",
                                fontSize: "16px",
                                fontWeight: "400",
                                marginBottom: "5px",
                                
                              }}
                            >
                              {item.name}
                            </p>
                            <p
                              style={{
                                color: "#439292",
                                fontSize: "16px",
                                fontWeight: "400",
                                margin: 0,
                                marginBottom: "5px",
                              }}
                            >
                              {item.day}
                            </p>

                          </div>
                          <div style={{ display: "flex", gap: "10px", paddingLeft: "3px" }}>
                            {item.holiday_type_display === "Optional Holiday" && (
                              <p
                                style={{
                                  color: "#FA7272",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  margin: 0,
                                  marginBottom: "5px",
                                }}
                              >
                                {item.holiday_type_display}
                              </p>
                            )}
                          </div>

                          <div style={{ display: "flex", gap: "10px" }}>
                            <img
                              src={edit}
                              alt="edit"
                              onClick={() => { handleEdit(month, item) }}
                              style={{ cursor: "pointer", height: "15px" }}
                            />
                            <img
                              src={deleteIcon}
                              alt="edit"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteItemDetail({
                                  ...deleteItemDetail,
                                  month: month,
                                  holiday_id: item.id
                                })
                              }}
                              style={{ cursor: "pointer", height: "20px" }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div style={{ height: "2px",width: "100%", background: "#F7F8FC", margin: "5px 0px" }}></div>
                    </div>
                  ))}
                </div>
                {/* <Divider style={{ marginTop: "10px" }} /> */}
              </div>
            ))}
          </div>

      </div>
    )
  }
  const renderHolidayData = () => {
    return (
      <div style={{
        backgroundColor: "#fff",
        // margin: "15px",
        borderRadius: "4px",
        padding: "20px",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px"
        }}>
          <p style={{ fontSize: "20px", fontWeight: "600", margin: "0px" }}>{policyName}</p>
          <Button
            style={{
              backgroundColor: "#008080",
              color: "#fff",
              padding: "0px 16px",
              borderRadius: "4px",
              height: "40px"
            }}
            onClick={() => setAddHolidayDetailsModal(true)}
          >
            Add Holiday
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "flex-start",
            alignItems: "stretch",

          }}
        >

          {Object.keys(holidayData).map((month) => (
            <div
              key={month}
              style={{
                width: "calc(50% - 20px)",
                minWidth: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h4
                style={{
                  color: "#5B5B5B",
                  fontSize: "18px",
                  fontWeight: "500",
                  marginTop: "10px",
                  marginBottom: "10px",
                  padding: "0px 16px",
                  color: "#008080",
                }}
              >
                {month}
              </h4>
              <div style={{ padding: "0px 16px", flexGrow: 1 }}>
                {holidayData[month].map((item) => (
                  <Row
                    key={item.id}
                    style={{
                      marginBottom: "10px",
                      borderBottom: "1px solid #F0F0F0",
                    }}
                    align="middle"
                    justify="space-between"
                  >
                    <Col
                      xs={{ span: 6 }}
                      style={{ textAlign: "center", paddingRight: "8px" }}
                    >
                      <p
                        style={{
                          color: "#292929",
                          fontSize: "18px",
                          fontWeight: "500",
                          margin: 0,
                        }}
                      >
                        {moment(item.date).format("DD")}
                      </p>
                    </Col>
                    <Col xs={{ span: 18 }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          <p
                            style={{
                              color: "#292929",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginBottom: "5px",
                            }}
                          >
                            {item.name}
                          </p>
                          <p
                            style={{
                              color: "#439292",
                              fontSize: "16px",
                              fontWeight: "400",
                              margin: 0,
                              marginBottom: "5px",
                            }}
                          >
                            {item.day}
                          </p>

                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          {item.holiday_type_display === "Optional Holiday" && (
                            <p
                              style={{
                                color: "#FA7272",
                                fontSize: "16px",
                                fontWeight: "400",
                                margin: 0,
                                marginBottom: "5px",
                              }}
                            >
                              {item.holiday_type_display}
                            </p>
                          )}
                        </div>

                        <div style={{ display: "flex", gap: "10px" }}>
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => { handleEdit(month, item) }}
                            style={{ cursor: "pointer", height: "15px" }}
                          />
                          <img
                            src={deleteIcon}
                            alt="edit"
                            onClick={() => {
                              setDeleteModal(true);
                              setDeleteItemDetail({
                                ...deleteItemDetail,
                                month: month,
                                holiday_id: item.id
                              })
                            }}
                            style={{ cursor: "pointer", height: "20px" }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
              <Divider style={{ marginTop: "10px" }} />
            </div>
          ))}
        </div>

      </div>

    );
  };
  return (
    <div >
      {
        screenSize.width<767?(
          <div style={{paddingTop: "10px"}}>
          {mwebrenderHolidayData()}
          </div>
        ):(
          <div>
              <div style={{ padding: "20px" }}>
                <img src={backArrow} alt="back"
                  onClick={() => {
                    navigate("/admin/leave/6")
                  }}
                  style={{ cursor: "pointer", marginBottom: "20px" }}
                />

                {renderHolidayData()}
              </div>
              <Modal
              title="Edit Holiday"
              centered
              open={holidayDetailsUpdateModal}
              onCancel={() => {
                // editForm.resetFields();
                setEditHolidayDetails({
                  ...editHolidayDetails,
                  id: "",
                  name: "",
                  holiday_date: "",
                  holiday_type: "",
                })
                setHolidayDetailsUpdateModal(false);


              }}
              width={500}
              footer={null}
            >
              <Form
                layout="vertical"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item label="Holiday Name"
                    // name="name"
                    >
                      <Input
                        size="large"
                        value={editHolidayDetails?.name}
                        onChange={(e) => setEditHolidayDetails({ ...editHolidayDetails, name: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Date"
                      // name="holiday_date"
                      rules={[{ required: true, message: "Please select a date" }]}
                    >
                      <DatePicker
                        size="large"
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => {
                          // console.log("Changed date...................", date, dateString);
                          setEditHolidayDetails({
                            ...editHolidayDetails,
                            holiday_date: dateString,
                          })
                        }}
                        // format={"YYYY-MM-DD"}
                        value={dayjs(editHolidayDetails?.holiday_date)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Holiday-Type"
                      // name="holiday_type"
                      rules={[{ required: true, message: "Please select a holiday type" }]}
                    >
                      <Select
                        size="large"
                        onChange={(value) =>
                          setEditHolidayDetails({ ...editHolidayDetails, holiday_type: value })
                        }
                        value={editHolidayDetails?.holiday_type ? editHolidayDetails?.holiday_type : null}
                      >
                        <Select.Option value={1}>Mandatory Holiday</Select.Option>
                        <Select.Option value={2}>Optional Holiday</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <button
                  style={{ width: "150px", color: "white", background: "#008080", height: "40px", border: "none", borderRadius: "5px" }}
                  onClick={() => {
                    onFinishForEdit()
                  }}
                >
                  Submit
                </button>
              </Form>
            </Modal>
            <Modal
              title="Add Holiday"
              centered
              open={addHolidayDetailsModal}

              onCancel={() => {
                setAddHolidayDetailsModal(false)
                addForm.resetFields();
              }}
              width={500}
              footer={null}
            >
              <Form
                layout="vertical"
                form={addForm}
                onFinish={onFinish}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item label="Holiday Name"
                      name="holiday_name">
                      <Input size="large"
                        onChange={(e) => onHandleAddHoliday("holiday_name", e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Date"
                      name="holidays_date"
                      rules={[{ required: true, message: "Please select a date" }]}
                    >
                      <DatePicker
                        size="large"
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => onHandleAddHoliday("holidays_date", dateString)}

                      // format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Holiday-Type"
                      name="holiday_type"
                    >
                      <Select size='large'
                        onChange={(value) => onHandleAddHoliday("holiday_type", value)}>
                        <Select.Option value={1}>Mandatory Holiday</Select.Option>
                        <Select.Option value={2}>Optional Holiday</Select.Option>
                      </Select>
                    </Form.Item>

                  </Col>
                </Row>
                <Button htmlType="submit"
                  style={{ width: "150px", color: "white", background: "#008080", height: "40px" }}
                >
                  Submit
                </Button>
              </Form>
            </Modal>

            <Modal
              // title="Delete Holiday"
              centered
              open={deleteModal}

              onCancel={() => {
                setDeleteModal(false)
                addForm.resetFields();
              }}
              width={500}
              footer={null}
            >
              <center>
                <img src={deleteImg} alt="deleteIcon" />
                <p style={{ fontSize: "18px", fontWeight: "600", marginTop: "20px" }}>Delete Holiday</p>
                <p>Are you sure you want to delete this Holiday?</p>
                <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "center" }}>
                  <button style={{ background: "#FF4040", color: "white", border: "none", borderRadius: "5px", height: "40px", padding: "0px 25px", fontWeight: "600" }}
                    onClick={() => {
                      handleDelete()
                    }}
                  >Proceed</button>
                  <button style={{ background: "none", color: "#2C2C2C", borderRadius: "1px solid #2C2C2C", borderRadius: "5px", height: "40px", padding: "0px 25px", fontWeight: "600" }}
                    onClick={() => {
                      setDeleteModal(false)
                    }}
                  >Cancel</button>
                </div>
              </center>
            </Modal>
          </div>
          
        )
      }
      <Modal
        title="Edit Holiday"
        centered
        open={holidayDetailsUpdateModal}
        onCancel={() => {
          // editForm.resetFields();
          setEditHolidayDetails({
            ...editHolidayDetails,
            id: "",
            name: "",
            holiday_date: "",
            holiday_type: "",
          })
          setHolidayDetailsUpdateModal(false);


        }}
        width={500}
        footer={null}
      >
        <Form
          layout="vertical"
        >
          <Row>
            <Col span={24}>
              <Form.Item label="Holiday Name"
              // name="name"
              >
                <Input
                  size="large"
                  value={editHolidayDetails?.name}
                  onChange={(e) => setEditHolidayDetails({ ...editHolidayDetails, name: e.target.value })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Date"
                // name="holiday_date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    // console.log("Changed date...................", date, dateString);
                    setEditHolidayDetails({
                      ...editHolidayDetails,
                      holiday_date: dateString,
                    })
                  }}
                  // format={"YYYY-MM-DD"}
                  value={dayjs(editHolidayDetails?.holiday_date)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Holiday-Type"
                // name="holiday_type"
                rules={[{ required: true, message: "Please select a holiday type" }]}
              >
                <Select
                  size="large"
                  onChange={(value) =>
                    setEditHolidayDetails({ ...editHolidayDetails, holiday_type: value })
                  }
                  value={editHolidayDetails?.holiday_type ? editHolidayDetails?.holiday_type : null}
                >
                  <Select.Option value={1}>Mandatory Holiday</Select.Option>
                  <Select.Option value={2}>Optional Holiday</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <button
            style={{ width: "150px", color: "white", background: "#008080", height: "40px", border: "none", borderRadius: "5px" }}
            onClick={() => {
              onFinishForEdit()
            }}
          >
            Submit
          </button>
        </Form>
      </Modal>
      <Modal
        title="Add Holiday"
        centered
        open={addHolidayDetailsModal}

        onCancel={() => {
          setAddHolidayDetailsModal(false)
          addForm.resetFields();
        }}
        width={500}
        footer={null}
      >
        <Form
          layout="vertical"
          form={addForm}
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="Holiday Name"
                name="holiday_name">
                <Input size="large"
                  onChange={(e) => onHandleAddHoliday("holiday_name", e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Date"
                name="holidays_date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => onHandleAddHoliday("holidays_date", dateString)}

                // format={"YYYY-MM-DD"}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Holiday-Type"
                name="holiday_type"
              >
                <Select size='large'
                  onChange={(value) => onHandleAddHoliday("holiday_type", value)}>
                  <Select.Option value={1}>Mandatory Holiday</Select.Option>
                  <Select.Option value={2}>Optional Holiday</Select.Option>
                </Select>
              </Form.Item>

            </Col>
          </Row>
          <Button htmlType="submit"
            style={{ width: "150px", color: "white", background: "#008080", height: "40px" }}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        // title="Delete Holiday"
        centered
        open={deleteModal}

        onCancel={() => {
          setDeleteModal(false)
          addForm.resetFields();
        }}
        width={500}
        footer={null}
      >
        <center>
          <img src={deleteImg} alt="deleteIcon" />
          <p style={{ fontSize: "18px", fontWeight: "600", marginTop: "20px" }}>Delete Holiday</p>
          <p>Are you sure you want to delete this Holiday?</p>
          <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "center" }}>
            <button style={{ background: "#FF4040", color: "white", border: "none", borderRadius: "5px", height: "40px", padding: "0px 25px", fontWeight: "600" }}
              onClick={() => {
                handleDelete()
              }}
            >Proceed</button>
            <button style={{ background: "none", color: "#2C2C2C", borderRadius: "1px solid #2C2C2C", borderRadius: "5px", height: "40px", padding: "0px 25px", fontWeight: "600" }}
              onClick={() => {
                setDeleteModal(false)
              }}
            >Cancel</button>
          </div>
        </center>
      </Modal>
    </div>
  );
}

export default HolidayDetails;