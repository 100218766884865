import { Input, Modal, Radio, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
// import Selective from './Selective/Selective';
import {Col, Row, Form} from 'antd'
import useScreenSize from '../../../../utils/getScreenSize';
function BulkDocumentReminder() {
    const params = useParams()

    const everyoneColumns = [
        {
            title: "ID",
            dataIndex: "employee_code",
            key: "employee_code"
        },
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
    ]

    const [bulkUploadType, setBulkUploadType] = useState("everyone");
    const [selectPolicyModal, setSelectPolicyModal] = useState(false);
    const [selectiveDataList, setSelectiveDataList] = useState([]);
    const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
    const [selectedEveryOneRowKeys, setSelectedEveryOneRowKeys] = useState([]);
    const [everyoneDataList, setEveryoneDataList] = useState([]);


    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 12,
        total: 0
    })

    const rowSelectionEveryOne = {
        selectedEveryOneRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedEveryOneRowKeys(selectedRowKeys)
        }
    }

    const screenSize = useScreenSize();
    const [changeByDropdownList, setChangeByDropdownList] = useState([]);
    const [selectedChangeBy, setSelectedChangeBy] = useState('');

    const [sentdResendMail, setSentdResendMail] = useState(false);

    const fetchEmployeesDetailList = async () => {
        try {

            let param = '';
            if (selectedChangeBy === 1) {
                param = 'departments'
            }
            else if (selectedChangeBy === 2) {
                param = 'designations'
            }
            else if (selectedChangeBy === 3) {
                param = 'functions'
            }
            else if (selectedChangeBy === 4) {
                param = 'grades'
            }
            else if (selectedChangeBy === 5) {
                param = 'work_locations'
            }
            const data = await request({
                method: "get",
                url: `attendance/admin/attendance/get-bulk-details?select_by=${bulkUploadType}&${param && `${param}=${selectedSelectBy}`}&page=${pagination.current}&page_size=${pagination.pageSize}`,
            })

            setEveryoneDataList(data?.data?.pagination_data?.data)

        }
        catch (error) {
            console.log("error", error)
        }
    }

    const fetchChangeByDropdownList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `admin/bulk-update/dropdown`,
            });

            setChangeByDropdownList(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const [selectByDropdownList, setSelectByDropdownList] = useState([]);
    const [selectedSelectBy, setSelectedSelectBy] = useState([]);

    const fetchSelectByDropdownList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `admin/bulk-update/select-by/dropdown?id=${selectedChangeBy}`,
            });

            setSelectByDropdownList(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchEmployeesDetailList();
        fetchChangeByDropdownList();
    }, [])

    useEffect(() => {
        if (selectedChangeBy) {
            fetchSelectByDropdownList();
        }
    }, [selectedChangeBy])

    useEffect(() => {
        if (bulkUploadType === 'everyone') {
            fetchEmployeesDetailList()
        }
    }, [selectedSelectBy])

    useEffect(() => {
        setSelectiveDataList([]);
        fetchEmployeesDetailList();
    }, [bulkUploadType])

    const updatePolicy = async () => {
        try {
            const data = await request({
                method: "post",
                url: `documentation/admin/policy-document/bulk-update`,
                data: {
                    id: params.id,
                    employees: selectedEveryOneRowKeys
                }
            })
            setSentdResendMail(true);
            fetchEmployeesDetailList();

        }
        catch (error) {
            console.log("error", error)
        }
    }




    return (
        <div>
            {screenSize.width < 768 ? (  
            <div style={{ padding: "0px 16px" }}>

                <div>
                    <Row gutter={[16, 16]} style={{marginTop: "90px"}}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                            style={{margin: "0px", color: "black"}}
                                name="Change By"
                                label="Change By"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            />
                            
                            {/* </Form.Item> */}
                            <Select
                                    size="large"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        console.log("e....", e);
                                        setSelectedChangeBy(e);
                                    }}
                                    placeholder="Select Change By"
                                >
                                    {changeByDropdownList.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>

                        </Col>

                        {/* Select By Dropdown */}
                        <Col xs={24} sm={12}>
                            {/* <p style={{ marginBottom: "8px", fontWeight: "500", color: "#444" }}>Select By</p> */}
                            <Form.Item 
                            style={{margin: "0px", color: "black"}}
                            name="Select By"
                                label="Select By"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}/>

                            <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    size="large"
                                    style={{ width: "100%" }}
                                    onChange={(e) => setSelectedSelectBy([...e])}
                                    placeholder="Select Select By"
                                >
                                    {selectByDropdownList?.map((item, index) => (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                        
                        </Col>
                    </Row>
                </div>

                <div style={{ marginTop: "24px" }}>
                    <Table
                    rowSelection={rowSelectionEveryOne}
                    columns={everyoneColumns}
                    dataSource={everyoneDataList}
                    pagination={false} 
                    
                    />

                </div>

        
                <button
                style={{position: "fixed", 
                            left:"10%",
                            top: "80vh", 
                            zIndex: 1, 
                            width:"80%",
                            backgroundColor: "rgb(0, 128, 128)",
                            color: "white",
                            height: "40px",
                            padding: "0px 15px",
                            border: "none",
                            borderRadius: "5px"}}
                    onClick={() => updatePolicy()}
                >
                    Send Reminders
                </button>

                <Modal
                    centered
                    open={sentdResendMail}
                    footer={null}
                    onOk={() => setSentdResendMail(false)}
                    onCancel={() => setSentdResendMail(false)}
                >
                    <center>
                        <img
                            src={
                                process.env.REACT_APP_CDN
                                    ? process.env.REACT_APP_CDN
                                    : "/media/Attendance/forgotImg.svg"
                            }
                            alt="forgotImg"
                            style={{ marginBottom: "16px" }}
                        />
                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>
                            Reminder Sent Successfully!
                        </p>
                        <span style={{ color: "#666" }}>
                            Your documentation reminders have been sent.
                        </span>
                    </center>
                </Modal>
            </div>
            

      ) : (
            <div style={{ margin: "20px" }}>
            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", lineHeight: "36px" }}>Bulk Reminder </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                    <Select
                        size='large'
                        style={{ width: "30%" }}
                        onChange={(e) => {
                            console.log("e....", e);
                            setSelectedChangeBy(e)
                        }}
                        placeholder="Select Change By"
                    >
                        {
                            changeByDropdownList.map((item, index) => {
                                return <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                    <Select
                        mode='multiple'
                        size='large'
                        style={{ width: "30%" }}
                        onChange={(e) => setSelectedSelectBy([...e])}
                        placeholder="Select Select By"
                    >
                        {
                            selectByDropdownList?.map((item, index) => {
                                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <button
                    style={{
                        border: "none",
                        background: "#008080",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: "4px",
                        height: "40px",
                        width: "150px",
                        marginLeft: "20px"
                    }}
                    onClick={() => updatePolicy()}
                >Send Reminders</button>
            </div>
            <div style={{ marginTop: "20px" }}>
                <Table
                    // rowKey={(record) => `everyone-${record.key}`}
                    rowSelection={rowSelectionEveryOne}
                    columns={everyoneColumns}
                    dataSource={everyoneDataList}
                />
            </div>

            <Modal
                centered
                open={sentdResendMail}
                footer={null}
                onOk={() => {setSentdResendMail(false)
                }}
                onCancel={() =>{
                    setSentdResendMail(false)
                }}
            >
                <center>
                <img
                    src={
                    process.env.REACT_APP_CDN
                        ? process.env.REACT_APP_CDN
                        : "/media/Attendance/forgotImg.svg"
                    }
                    alt="forgotImg"
                />
                <p>Reminder Sent Successfully!</p>
                <span>Your documentation reminders has been sent.</span>
                </center>
            </Modal>

        </div>
      )}
             
        </div>
       
    )
}

export default BulkDocumentReminder