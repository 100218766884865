import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import request from '../../../../../../utils/axios';
import { Row, Col, Select, Modal, Form, Input, Button, Spin } from 'antd';
import useScreenSize from '../../../../../../utils/getScreenSize';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
function ViewUpload() {
    const params = useParams();
    const screenSize = useScreenSize()
    const [selectedReimbursementData, setSelectedReimbursementData] = useState();
    const [selectedOptionEmployeeId, setSelectedOptionEmployeeId] = useState("")
    const [approvedModal, setApprovedModal] = useState(false)
    const [partiallyApprovedLoader, setPartiallyApprovedLoader] = useState(false)

    const fetchReimbursementData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `payroll/admin/get-employee-reimbursement-details?id=${params.id}`,
      })
      setSelectedReimbursementData(data.data)
    console.log(params.id)
      console.log(data. data + "fetchReimursement")
    }
    catch (err) {
      console.log(err)
    }
  }
  const onFinishForReimbursement = async (values) => {
    try {
      setPartiallyApprovedLoader(true)
      await request({
        method: "post",
        url: `payroll/admin/reimbursement-partially-approved`,
        data: {
          id: selectedOptionEmployeeId,
          approved_amount: values.amount
        }
      })
      setPartiallyApprovedLoader(false)
      
      fetchReimbursementData()
      setApprovedModal(false);
      toast.success("Reimbursement Request Partially Approved Successfully", {
        position: "bottom-left",
      })

    }
    catch (error) {
      console.log(error)
      setPartiallyApprovedLoader(false)
      toast.error("Reimnursement Request Could Not Be Partially Approved, Please Try After Some Time", {
        position: "bottom-left",
      })
    }
  }
  const approveEmployeeReimbursementAmount = async (id) => {
    try {
      await request({
        method: "post",
        url: `payroll/admin/reimbursement-approved`,
        data: {
          id
        }
      })
      fetchReimbursementData();
    }
    catch (error) {
      console.log(error)
    }
  }
    const onHandleChangeForReimbursementAmount = (value, id) => {
    //   partially approved
    if (value === 1) {
      setApprovedModal(true)
      setSelectedOptionEmployeeId(id)
    }
    // approved
    else if (value === 2) {
      approveEmployeeReimbursementAmount(id)
    }
  }
    useEffect(() => {
        fetchReimbursementData()
    }, [])


    return (
        <div >
            {
                screenSize.width<767?
                (<div style={{ padding: "16px", fontSize: "18px" }}>
                    {
                        selectedReimbursementData && (
                            <Row gutter={[0, 16]}>
                                {selectedReimbursementData?.title && (
                            <Col 
                                span={24} >
                                <div style={{ display: 'flex', gap:"20px", justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{
                                    display: 'flex',
                                    }}>
                                        <p>{selectedReimbursementData?.title}</p>
                                        <div 
                                            style={{
                                            padding: "3px 8px", 
                                            color: "#28B15F",
                                            border: "1px solid #28B15F",
                                            borderRadius: "15px", 
                                            fontSize: "10px", 
                                            textAlign: "center",
                                            marginLeft: "3px"
                                            }}
                                        >
                                            {selectedReimbursementData?.approved === 1 
                                            ? "Partially Approved" 
                                            : selectedReimbursementData?.approved === 2 
                                                ? "Approved" 
                                                : "Pending"}
                                        </div>
                                    
                                    </div>
                                    <Select
                                        value={selectedReimbursementData.approved === null ? 0 : selectedReimbursementData.approved}
                                        onChange={(value) => onHandleChangeForReimbursementAmount(value, selectedReimbursementData.id)}
                                        // popupMatchSelectWidth={false}
                                        className="custom-select"
                                        style={{fontSize: "16px", widgth:"150px"}}
                                        dropdownStyle={{fontSize: "16px"}}
                                        size="small"
                                    >
                                        <Select.Option value={0}>Select</Select.Option>
                                        <Select.Option value={1}>Partially Approved</Select.Option>
                                        <Select.Option value={2}>Approved</Select.Option>
                                    </Select>
                                
                                </div>
                                
                                {/* <div 
                                style={{ 
                                    display: "flex", 
                                    justifyContent: "flex-end", 
                                    alignItems: "center", 
                                    gap: "10px" 
                                }}
                                >
                                

                                
                                </div> */}
                            </Col>
                            )}

                                {selectedReimbursementData?.employee_name && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                                        <span style={{color: "#8a8a8a"}}>Name</span>
                                        <span>{selectedReimbursementData?.employee_name}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.category && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Category</span>
                                        <span>{selectedReimbursementData?.category}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.claimed_amount && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span> style={{color: "#8a8a8a"}}Amount Claimed</span>
                                        <span>{selectedReimbursementData?.claimed_amount}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.approved_amount && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Amount Approved</span>
                                        <span>₹{selectedReimbursementData?.approved_amount}</span>
                                    </Col>
                                )}
                                
                                {selectedReimbursementData?.date && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Claimed On</span>
                                        <span>{selectedReimbursementData?.date}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.invoice_id && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Invoice ID</span>
                                        <span>{selectedReimbursementData?.invoice_id}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.merchant_name && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Merchant Name</span>
                                        <span>{selectedReimbursementData?.merchant_name}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.no_of_kilometer && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>No. of Kilometer</span>
                                        <span>{selectedReimbursementData?.no_of_kilometer}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.from_date && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <span style={{color: "#8a8a8a"}}>From Date / </span>
                                            <span style={{color: "#8a8a8a"}}>Start Time</span>
                                        </div>
                                        <div>
                                            <span>{selectedReimbursementData?.from_date} / </span>
                                            <span>{selectedReimbursementData?.start_time}</span>
                                        </div>
                                        
                                    </Col>
                                )}
                                



                                {selectedReimbursementData?.from_place && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>From Place</span>
                                        <span>{selectedReimbursementData?.from_place}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.to_place && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>To Place</span>
                                        <span>{selectedReimbursementData?.to_place}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.no_of_employees && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>No. of Employees</span>
                                        <span>{selectedReimbursementData?.no_of_employees}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.participants && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Participants</span>
                                        <span>{selectedReimbursementData?.participants}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.no_of_days && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>No. of Days</span>
                                        <span>{selectedReimbursementData?.no_of_days}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.end_time && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>End Time</span>
                                        <span>{selectedReimbursementData?.end_time}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.to_date && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>To Date</span>
                                        <span>{selectedReimbursementData?.to_date}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.location && (
                                    <Col span={24} style={{ display: 'flex', gap: "20px", textAlign:"end" }}>
                                        <span style={{color: "#8a8a8a"}}>Location</span>
                                        <span>{selectedReimbursementData?.location}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.description && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>Description</span>
                                        <span>{selectedReimbursementData?.description}</span>
                                    </Col>
                                )}
                                {selectedReimbursementData?.to_date && (
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{color: "#8a8a8a"}}>To Date</span>
                                        <span>{selectedReimbursementData?.to_date}</span>
                                    </Col>
                                )}
                            </Row>

                        )
                    }
                            <Modal
                            title="Approve Reimbursement Amount"
                            centered
                            footer={null}
                            onCancel={() => setApprovedModal(false)}
                            open={approvedModal}>
                            <Form layout="vertical"
                                onFinish={onFinishForReimbursement} >
                                <Col span={24}>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Amount!',
                                    }
                                    ]}
                                >
                                    <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    />
                                </Form.Item>

                                </Col>
                                <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                                {
                                partiallyApprovedLoader 
                                    ?
                                    <Spin
                                        indicator={
                                        <LoadingOutlined
                                            style={{ fontSize: "15px", color: "white" }}
                                            spin
                                        />
                                        }
                                    />
                                    :
                                    "Submit"
                                }
                                </Button>
                            </Form>
                            </Modal>
                </div>):(null)
            }
            
        </div>
    )
}

export default ViewUpload