import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import "./PolicyDocumentEmployeeSearchBar.scss"
// import "./AttendanceReportSearchBar.scss"

function PolicyDocumentEmployeeSearchBar({
    setTableData, // set table data for search employee 
    setHasMore,
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
    setPagination,
    placeholderText,
    empType,
    // setEmployeeId
}) {
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const params = useParams()

    const handleSelectChange = async (employement_number, employee_name) => {
        try {
            setSearchValue(employee_name)
            // setEmployeeId(value)
            const response = await request({
                method: "get",
                url: `documentation/admin/employee/policy-document/insights?id=${params.id}&employement_number=${employement_number}`,
            })

            setTableData([...response.data])
            setHasMore(false)
            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            setSearchValue(data)
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=${empType}`,
            })

            setSearchEmployeeList(response)
            if (data === "") {
                setSearchEmployeeList([])
                const newPagination = {
                    current: 1,
                    pageSize: 12,
                    total: 0,
                };
                setPagination(newPagination)
                const dataList = []
                setHasMore(true);
                fetchTableDataFromApi(newPagination, dataList)
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <Input
                type="text"
                placeholder={placeholderText}
                onChange={(e) => search(e.target.value)}
                // size='small'
                value={searchValue}
                prefix={<SearchOutlined />}
                allowClear
            />
            {searchEmployeeList.length > 0 && (
                <ul>
                    {searchEmployeeList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.employement_number, item.employee_name)}>
                            {item.employee_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default PolicyDocumentEmployeeSearchBar