import React from 'react';
import error from "../../assets/Error.svg";

function ErrorFallback() {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "center" }}>
      <center>
        <img src={error} alt="" width={60} height={60} style={{ marginBottom: "20px" }} />
        <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>Oops! Something Went Wrong</p>
        <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>We’re having trouble processing your request right now.</p>
        <button style={{
          background: "#008080",
          color: "white",
          border: "none",
          padding: "0px 20px",
          height: "40px",
          borderRadius: "5px",
          fontWeight: "600",
          cursor: "pointer"
        }}
          onClick={() => window.location.reload()}
        >Retry</button>
      </center>
    </div>
  )
}

export default ErrorFallback