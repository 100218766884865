import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { Provider } from 'react-redux'
import Main from './Main';


const root = ReactDOM.createRoot(document.getElementById('root'));
const loading = document.getElementById('loading');
if (loading) loading.style.display = 'none';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* Main component is formed to handle routing dynamically */}
      <Main />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
