import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal, Switch } from "antd";

import "./MobileMenu.scss";

import mobileMenuList from "../../data/mobileMenuList";
import adminMobileMenu from "../../data/adminMobileMenu";

// import org from "../../assets/MobileMenu/more/org.jpg";
// import profile from "../../assets/MobileMenu/more/profile.jpg";
// import employee from "../../assets/MobileMenu/more/employee.jpg";
// import leave from "../../assets/MobileMenu/more/leave.jpg";
// import payRoll from "../../assets/MobileMenu/more/payroll.jpg";
// import job from "../../assets/MobileMenu/more/job.jpg";
// import docs from "../../assets/MobileMenu/more/docs.jpg";
import headerprofile from "../../assets/headerprofile.svg";
import logout from "../../assets/logout.svg";
import request from "../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileInRedux } from "../../redux/reducer/UserProfileReducer";
import InitiateSepration from "../../assets/InitiateSepration.png";
import getScreenSize from "../../utils/getScreenSize";
// import userProfileImg from "../../assets/user.png";
import { toast } from "react-toastify";
// import Tracking from "../../assets/MobileMenu/more/tracking.jpg";
import deleteIcon from "../../assets/delete.png";
import bullet from "../../assets/bullet.svg";
import SuspenseWrapper from "../SuspenseWrapper/SuspenseWrapper";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback/ErrorFallback";
// import feeds from "../../assets/MobileMenu/more/feeds.png";

function MobileMenu({ content, menuTitle, showBackButton }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [profileModel, setProfileModel] = useState(false);
  const userProfile = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [initiateSeprationModel, setInitialSeperationModel] = useState(false);

  const is_admin = JSON.parse(localStorage.getItem("is_admin"));
  const role = localStorage.getItem("role");
  const role_list = JSON.parse(localStorage.getItem("role_list"));
  const profileRef = useRef(null);
  const screenSize = getScreenSize();
  const [form] = Form.useForm();


  const navigate = useNavigate();

  const handleItemClick = (key) => {
    if (key === "more") {
      toggleMenu();
    }
    else {
      setActiveItem(key);
    }
  };

  const toggleMenu = (label) => {
    setIsOpen(!isOpen);
    if (label === "attendance" || label === "profile") {
      setActiveItem(label);
    }
    //  else {
    //   setActiveItem("more");
    // }
  };

  const switchAvatar = (userType) => {
    localStorage.setItem("role", userType);
    navigate("/dashboard");
  };

  const fetchUserProfileImage = async () => {
    try {
      const data = await request({
        method: "GET",
        url: "/users/dashboard/profile-image",
      });

      dispatch(
        setUserProfileInRedux({
          ...userProfile,
          profile_image: data.profile_image,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserProfileImage();
  }, []);

  let location = useLocation();
  const currentUrl = location.pathname;

  const logoutUser = async () => {
    try {
      let deviceType;
      if (screenSize.width < 768) {
        // mobile
        deviceType = 0;
      } else if (screenSize.width < 1440) {
        // laptop
        deviceType = 1;
      } else {
        // desktop
        deviceType = 2;
      }

      await request({
        method: "post",
        url: `/users/logout`,
        data: {
          device_type: deviceType,
        },
      });

      localStorage.clear();
      setProfileModel(!profileModel);
      let logout = "logout";
      if (
        window.ReactNativeWebView &&
        typeof window.ReactNativeWebView.postMessage === "function"
      ) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ logout }));
      } else {
        console.warn("ReactNativeWebView is not defined.");
      }
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const urlContainAdmin = currentUrl.split("/")[1] === "admin";
    const moduleName = currentUrl.split("/")[2];

    const role = localStorage.getItem("role");
    if (role === "Admin") {
      if (urlContainAdmin && is_admin) {
        const isAuthorized = role_list?.includes(moduleName);
        if (isAuthorized) {
          // navigate(currentUrl);
        } else {
          // navigate("/dashboard");
        }
      }
    }
    else {
      const moduleName = currentUrl.split("/")[1];
      localStorage.setItem("activeMenu", moduleName);

      setActiveItem(moduleName);
    }

    // else if (!urlContainAdmin) {
    //   navigate(currentUrl);
    // }

    const path = window.location.pathname;

    if (role === "Admin") {
      if (path.includes("dashboard")) {
        setActiveItem("home");
      } else if (path.includes("attendance")) {
        setActiveItem("attendance");
      } else if (path.includes("leave")) {
        setActiveItem("leave");
      } else {
        setActiveItem("more");
      }
    } else {
      if (path.includes("dashboard")) {
        setActiveItem("home");
      } else if (path.includes("attendance")) {
        setActiveItem("attendance");
      } else if (path.includes("inbox")) {
        setActiveItem("inbox");
      } else {
        setActiveItem("more");
      }
    }
  }, [currentUrl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Clicked outside the popup, so close it
        setProfileModel(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [resignationDetails, setResignationDetails] = useState({
    resignation_date: "",
    last_working_date: "",
    requested_date: "",
    recovery_days: "",
    reason: "",
  });

  const [noticePeriod, setNoticePeriod] = useState(null);

  const getMaxRequestedDate = (resignationDate) => {
    return dayjs(resignationDate).add(noticePeriod, "day");
  };

  const handleFormChange = (changedValues, allValues) => {
    setResignationDetails(allValues);
    console.log(changedValues);
    console.log(allValues);
    console.log(resignationDetails);

    // Check if the resignation_date has changed
    if (changedValues.resignation_date) {
      const resignationDate = changedValues.resignation_date;
      const lastWorkingDate = dayjs(resignationDate)
        .add(noticePeriod, "day")
        .format("YYYY-MM-DD");

      // Update the state with the new last working date
      setResignationDetails((prev) => ({
        ...prev,
        last_working_date: lastWorkingDate,
      }));
    }
    if (changedValues.requested_date) {
      const last_working_date = allValues.last_working_date;
      const requested_date = allValues.requested_date;
      const recoveryDays = dayjs(last_working_date).diff(requested_date, "day");

      // Update the state with recovery days if needed
      setResignationDetails((prev) => ({
        ...prev,
        recovery_days: recoveryDays > 0 ? recoveryDays : 0,
      }));
    }
  };

  const getNoticePeriod = async () => {
    const apiUrl = `employee/notice-period`;

    try {
      const res = await request({
        method: "POST",
        url: apiUrl,
      });
      setNoticePeriod(res.notice_period);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const initiateSeparation = async () => {
    console.log(resignationDetails);
    try {
      const payload = {
        resignation_date: dayjs(resignationDetails.resignation_date).format(
          "YYYY-MM-DD"
        ),
        requested_date: dayjs(resignationDetails.requested_date).format(
          "YYYY-MM-DD"
        ),
        recovery_days: resignationDetails.recovery_days,
        last_working_date: resignationDetails.last_working_date,
        reason: resignationDetails.reason,
      };
      console.log(payload);
      await request({
        method: "post",
        url: `/employee/resignation/create`,
        data: payload,
      });

      toast.success("Resignation Initiated Successfully", {
        position: "bottom-left",
      });
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data.message, {
        position: "bottom-left",
      });
    } finally {
      setInitialSeperationModel(false);
      setResignationDetails({});
    }
  };

  const [isApp, setIsApp] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    setIsApp(userAgent.includes('MyAppWebView/1.0'));
  }, []);

  const [deleteAccount, setDeleteAccount] = useState(false);
  const [accountDeletionRequested, setAccountDeletionRequested] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      await request({
        method: "post",
        url: "employee/account/delete",
      })

      setAccountDeletionRequested(true);
      setDeleteAccount(false);
    }
    catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (initiateSeprationModel) {
      getNoticePeriod();
    }
  }, [initiateSeprationModel]);

  const handleError = async (error, errorInfo) => {
    const baseUrl = process.env.REACT_APP_FRONT_APP_BASE_URL;
    const url = window.location.href;
    const userId = localStorage.getItem("user_id");
    const employeeId = localStorage.getItem("employee_number");
    try {
      await request({
        method: "post",
        url: "users/mailer/fe",
        data: {
          subject: "Error Details",
          message: `
          Tenant Name: ${url.split(baseUrl)[1].split("/")[1]}
          url : ${url}
          userId : ${userId}
          employeeId : ${employeeId}
          `,
        }
      })
      console.log("Error details sent via email", error, errorInfo);
    } catch (err) {
      console.error("Failed to send error details via email:", err);
    }
  };

  return (
    <div className="mobileMenu">
      <div className="content">
        <div className="header flexSpaceBetween">
          {is_admin ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                  paddingLeft: "16px",
                }}
              >
                {showBackButton && (
                  <span
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <LeftOutlined style={{ color: "white" }} />
                  </span>
                )}
                <p className="menuTitle">{menuTitle}</p>
              </div>
              <div className="avatar-container">
                <Switch
                  checked={role === "Admin" ? true : false}
                  onChange={(e) => {
                    switchAvatar(e ? "Admin" : "Emp");
                    if (e) {
                      navigate("/admin/dashboard");
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                />
                <span style={{ fontWeight: "600", marginLeft: "4px" }}>
                  {" "}
                  Admin
                </span>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "15px",
                  zIndex: "999",
                }}
                onClick={() => {
                  setProfileModel(!profileModel);
                  setIsOpen(false);
                }}
              >
                <div
                  style={{
                    // position: "relative",
                    right: "0px",
                    border: "2px solid #3D2879",
                    height: "32px",
                    width: "32px",
                    borderRadius: "50%",
                    background: "white",
                    color: "black",
                    fontSize: "10px",
                    fontWeight: "600",
                    zIndex: "1",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      userProfile?.profile_image
                        ? userProfile.profile_image
                        : process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/user.png"
                    }
                    alt=""
                    style={{
                      borderRadius: "50%",
                      top: "0px",
                      width: "100%",
                      height: "100%",
                      // position: "relative",
                    }}
                  />
                </div>
              </div>
              {profileModel && (
                <div class="arrowContainer" ref={profileRef}>
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                      textAlign: "start",
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <li
                        onClick={() => {
                          navigate("/user-profile");
                          setProfileModel(!profileModel);
                        }}
                        style={{
                          lineHeight: "50px",
                        }}
                      >
                        <img
                          src={headerprofile}
                          alt=""
                          width={15}
                          height={15}
                        />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Profile
                        </span>
                      </li>
                      <li
                        onClick={() => logoutUser()}
                        style={{
                          lineHeight: "50px",
                        }}
                      >
                        <img src={logout} alt="Avatar" width={15} height={15} />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Logout
                        </span>
                      </li>
                      <li
                        onClick={() => {
                          setInitialSeperationModel(true)
                          setProfileModel(false);

                        }}
                        style={{
                          lineHeight: "50px",
                        }}
                      >
                        <img
                          src={InitiateSepration}
                          alt="Avatar"
                          width={15}
                          height={15}
                        />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Initiate Sepration
                        </span>
                      </li>
                      {
                        isApp && <li
                          onClick={() => setDeleteAccount(true)}
                          style={{
                            lineHeight: "50px",
                          }}
                        >
                          <img
                            src={InitiateSepration}
                            alt="Avatar"
                            width={15}
                            height={15}
                          />
                          <span
                            style={{
                              color: "#8E8E8E",
                              fontWeight: "400",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            Delete Account
                          </span>
                        </li>
                      }
                    </div>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                  paddingLeft: "16px",
                }}
              >
                {showBackButton && (
                  <span
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <LeftOutlined style={{ color: "white" }} />
                  </span>
                )}
                <p className="menuTitle">{menuTitle}</p>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "15px",
                  zIndex: "999",
                }}
                onClick={() => {
                  setProfileModel(!profileModel);
                  setIsOpen(false);
                }}
              >
                <div
                  style={{
                    // position: "relative",
                    right: "0px",
                    border: "2px solid #3D2879",
                    height: "32px",
                    width: "32px",
                    borderRadius: "50%",
                    background: "white",
                    color: "black",
                    fontSize: "10px",
                    fontWeight: "600",
                    zIndex: "1",
                  }}
                >
                  <img
                    src={userProfile?.profile_image}
                    alt=""
                    width={25}
                    height={25}
                    style={{
                      borderRadius: "50%",
                      top: "0px",
                      position: "relative",
                    }}
                  />
                </div>
              </div>

              {profileModel && (
                <div class="arrowContainer" ref={profileRef}>
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                      textAlign: "start",
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <li
                        onClick={() => {
                          navigate("/user-profile");
                          setProfileModel(!profileModel);
                        }}
                        style={{
                          lineHeight: "40px",
                        }}
                      >
                        <img src={headerprofile} alt="" />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Profile
                        </span>
                      </li>
                      <li
                        onClick={() => logoutUser()}
                        style={{
                          lineHeight: "50px",
                        }}
                      >
                        <img src={logout} alt="Avatar" />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Logout
                        </span>
                      </li>
                      <li
                        onClick={() => setInitialSeperationModel(true)}
                        style={{
                          lineHeight: "50px",
                        }}
                      >
                        <img
                          src={InitiateSepration}
                          alt="Avatar"
                          width={15}
                          height={15}
                        />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Initiate Sepration
                        </span>
                      </li>
                      {
                        isApp && <li
                          onClick={() => setDeleteAccount(true)}
                          style={{
                            lineHeight: "50px",
                          }}
                        >
                          <img
                            src={InitiateSepration}
                            alt="Avatar"
                            width={15}
                            height={15}
                          />
                          <span
                            style={{
                              color: "#8E8E8E",
                              fontWeight: "400",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            Delete Account
                          </span>
                        </li>
                      }
                    </div>
                  </ul>
                </div>
              )}
              <div className="avatar-container">{/* empty */}</div>
            </>
          )}
        </div>
        <div className="main-content">
          <SuspenseWrapper>
            <ErrorBoundary
              fallback={<ErrorFallback />}
              onError={handleError}
            >
              {content}
            </ErrorBoundary>
          </SuspenseWrapper>
          <div style={{ height: "100px" }}></div>
        </div>
        <div className="footer">
          {isOpen && <MorePopUp toggleMenu={toggleMenu} />}
          <ul style={{ justifyContent: "space-around", height: "76px" }}>
            {role === "Admin"
              ? adminMobileMenu.map((item, index) => {
                return (
                  <Link to={item.path}>
                    <li
                      key={index}
                      onClick={() => {
                        handleItemClick(item.key);
                        if (isOpen) {
                          setIsOpen(false);
                        }
                      }}
                    >
                      <div className="menuItemContainer">
                        <img
                          src={
                            activeItem === item.key
                              ? item.activeImage
                              : item.image
                          }
                          width={24}
                          height={24}
                        />
                        <p
                          className={` ${activeItem === item.key
                            ? "activeMenuLabel"
                            : "menuLabel"
                            }`}
                        >
                          {item.label}
                        </p>
                      </div>
                    </li>
                  </Link>
                );
              })
              : mobileMenuList.map((item, index) => {
                return (
                  <Link to={item.path}>
                    <li
                      key={index}
                      onClick={() => {
                        handleItemClick(item.key);
                        if (isOpen) {
                          setIsOpen(false);
                        }
                      }}
                    >
                      <div className="menuItemContainer">
                        <img
                          src={
                            activeItem === item.key
                              ? item.activeImage
                              : item.image
                          }
                          width={24}
                          height={24}
                        />
                        <p
                          className={` ${activeItem === item.key
                            ? "activeMenuLabel"
                            : "menuLabel"
                            }`}
                        >
                          {item.label}
                        </p>
                      </div>
                    </li>
                  </Link>
                );
              })}
          </ul>
        </div>
      </div>

      <Modal
        centered
        open={initiateSeprationModel}
        onOk={() => setInitialSeperationModel(false)}
        onCancel={() => setInitialSeperationModel(false)}
        footer={null}
        width={360} // Adjusting width for smaller screens
        style={{ padding: "16px" }}
      >
        <p style={{ fontSize: "18px", textAlign: "center", marginBottom: "16px" }}>
          Initiate Separation
        </p>

        <Form
          layout="vertical"
          onFinish={initiateSeparation}
          onValuesChange={handleFormChange}
          form={form}
          autoComplete="on"
          fields={[
            {
              name: ["resignation_date"],
              value: resignationDetails.resignation_date,
            },
            {
              name: ["last_working_date"],
              value: resignationDetails.last_working_date,
            },
            {
              name: ["requested_date"],
              value: resignationDetails.requested_date,
            },
            {
              name: ["recovery_days"],
              value: resignationDetails.recovery_days,
            },
            {
              name: ["reason"],
              value: resignationDetails.reason,
            },
          ]}
        >
          <Form.Item
            label="Resignation Date"
            name="resignation_date"
            rules={[
              {
                required: true,
                message: "Please select resignation date",
              },
            ]}
            style={{ marginBottom: "12px" }}
          >
            <DatePicker
              size="large"
              style={{
                width: "100%",
                fontSize: "14px",
                height: "40px",
              }}
              defaultValue={dayjs(resignationDetails.startTime, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              name="resignation_date"
            />
          </Form.Item>

          <Form.Item
            label="Last Working Date"
            name="last_working_date"
            style={{ marginBottom: "12px" }}
          >
            <Input
              size="large"
              disabled
              name="last_working_date"
              style={{
                width: "100%",
                fontSize: "14px",
                height: "40px",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Requested Date"
            name="requested_date"
            rules={[
              {
                required: true,
                message: "Please select requested date",
              },
            ]}
            style={{ marginBottom: "12px" }}
          >
            <DatePicker
              size="large"
              style={{
                width: "100%",
                fontSize: "14px",
                height: "40px",
              }}
              defaultValue={dayjs(resignationDetails.startTime, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              name="requested_date"
              disabledDate={(current) => {
                const resignationDate = dayjs(
                  resignationDetails.resignation_date
                );
                const maxRequestedDate = getMaxRequestedDate(resignationDate);
                return (
                  current &&
                  (current < resignationDate || current > maxRequestedDate)
                );
              }}
            />
          </Form.Item>

          <Form.Item
            label="Recovery Days"
            name="recovery_days"
            style={{ marginBottom: "12px" }}
          >
            <Input
              size="large"
              disabled
              style={{
                width: "100%",
                fontSize: "14px",
                height: "40px",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Please add Reason!",
              },
            ]}
            style={{ marginBottom: "16px" }}
          >
            <TextArea
              rows={4}
              placeholder="Enter Your Reason"
              className="leaveTextArea"
              style={{
                width: "100%",
                fontSize: "14px",
                borderRadius: "4px",
              }}
            />
          </Form.Item>

          <Button
            htmlType="submit"
            style={{
              color: "white",
              background: "#008080",
              border: "1px solid #008080",
              height: "40px",
              width: "100%",
              borderRadius: "4px",
              fontSize: "16px",
            }}
          >
            Send Request
          </Button>
        </Form>
      </Modal>



      <Modal
        centered
        open={deleteAccount}
        onOk={() => setDeleteAccount(false)}
        onCancel={() => setDeleteAccount(false)}
        footer={null}
        width={600}
      >
        <center>
          <img src={deleteIcon} alt="deleteIcon" />
        </center>
        <p style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", fontWeight: "600" }}>Delete Account</p>
        <p style={{ fontSize: "13px", textAlign: "center" }}>
          Are you sure you want to delete this account?
          <br />
          This action cannot be undone
        </p>
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <button
            style={{
              color: "white",
              border: "none",
              background: "#FF4040",
              height: "40px",
              width: "100px",
              borderRadius: "5px",
            }}
            onClick={() => handleDeleteAccount(false)}
          >
            Proceed
          </button>
          <button
            style={{
              color: "#2C2C2C",
              background: "none",
              border: "1px solid #2C2C2C",
              height: "40px",
              width: "100px",
              borderRadius: "5px",
            }}
          // onClick={() => initiateSeparation()}
          >
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        centered
        open={accountDeletionRequested}
        onOk={() => setAccountDeletionRequested(false)}
        onCancel={() => setAccountDeletionRequested(false)}
        footer={null}
        width={600}
      >
        <center>
          <img src={bullet} alt="bullet" width={40} height={40} />
        </center>
        <p style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", fontWeight: "600", marginTop: "10px" }}>Account Deletion Requested</p>
        <p style={{ fontSize: "13px", textAlign: "center" }}>
          Your request for account deletion has been sent to the administrator for approval.
        </p>
      </Modal>
    </div>
  );
}
export default MobileMenu;

const MorePopUp = ({ toggleMenu }) => {
  const role = localStorage.getItem("role");
  const [data, setData] = useState([]);

  const moreMenuList = [
    {
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/job.jpg",
      path: "/recruitment",
      label: "Job",
    },
    {
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/payroll.jpg",
      path: "/payroll",
      label: "Payroll",
    },
    {
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/leave.jpg",
      path: "/leave",
      label: "Leave",
    },
    {
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/profile.jpg",
      path: "/user-profile",
      label: "Profile",
    },
    {
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/tracking.jpg",
      path: "/tracking",
      label: "Tracking",
    },
    {
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/feeds.png",
      path: "/feeds",
      label: "Feeds",
    }
  ];

  const adminMoreMenuList = [
    {
      key: "org",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/org.jpg",
      path: "/admin/org",
      label: "Org",
    },
    {
      key: "payroll",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/payroll.jpg",
      path: "/admin/payroll",
      label: "Payroll",
    },
    {
      key: "recruitment",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/job.jpg",
      path: "/admin/recruitment",
      label: "Job",
    },
    {
      key: "employee",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/employee.jpg",
      path: "/admin/employee",
      label: "employees",
    },
    {
      key: "profile",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/profile.jpg",
      path: "/user-profile",
      label: "Profile",
    },
    {
      key: "docs",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/docs.jpg",
      path: "/admin/docs",
      label: "Documents",
    },
    {
      key: "tracking",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/tracking.jpg",
      path: "/admin/tracking",
      label: "Tracking",
    },
    {
      key: "feeds",
      image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more/feeds.png",
      path: "/admin/feeds",
      label: "Feeds",
    }
  ];

  useEffect(() => {
    const roleList = JSON.parse(localStorage.getItem("role_list")) || [];

    const filteredData = adminMoreMenuList.filter((item) =>
      roleList?.includes(item.key)
    );
    setData(filteredData);
  }, []);

  let menuList = role === "Admin" ? data : moreMenuList;

  return (
    <div className="backgroundContainer">
      <div className="morePopUpContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              flex: 1,
              textAlign: "center",
              fontSize: 24,
              marginTop: "30px",
              marginBottom: "50px",
              fontSize: "18px",
              fontWeight: 600,
              color: "#161616",
            }}
          >
            More
          </p>
          <div style={{ marginRight: "10px", marginTop: "-15px" }}>
            <CloseOutlined
              onClick={() => toggleMenu()}
              style={{ color: "#5C5E61" }}
            />
          </div>
        </div>
        <div className="more">
          <div className="grid-container">
            {menuList.map((item, index) => {
              return (
                <Link
                  to={item.path}
                  onClick={() => toggleMenu(item.label)}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={item.image}
                    alt={item.image}
                    width={52}
                    height={52}
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      color: "#5C5E61",
                      fontSize: "12px",
                    }}
                  >
                    {item.label}
                  </p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
