import React from 'react';
import getScreenSize from '../../utils/getScreenSize';
const ContainerLayout = ({children}) => {
  const screenSize = getScreenSize();
  return (
    <>
      {screenSize.width < 768 ? (
        <div>{children}</div>
      ) : (
        <div style={{padding: '20px'}}>{children}</div>
      )}
    </>
  );
};

export default ContainerLayout;
